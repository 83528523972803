<template>
  <v-card :class="isClass">
    <v-speed-dial
      :fixed="isFixed"
      :absolute="isAbsolute"
      v-model="fab"
      bottom
      right
      :open-on-hover="isHover"
      :transition="transitionTo"
    >
      <div v-if="mode == 'log'">
        <v-btn
          @click="$emit('onDialogExportOpen')"
          class="white--text"
          fab
          small
          color="#097c7c"
        >
          <v-icon> mdi-file-export-outline</v-icon>
        </v-btn>
        <v-btn
          @click="$emit('onDialogFilterOpen')"
          class="white--text"
          fab
          small
          color="#09227C"
        >
          <v-icon> mdi-filter-variant</v-icon>
        </v-btn>

        <v-btn
          v-if="!isDetailLogNone"
          :small="$vuetify.breakpoint.xs"
          :width="$vuetify.breakpoint.xs ? '' : '135'"
          :fab="$vuetify.breakpoint.xs"
          depressed
          color="#03DAC6"
          class="ma-2 text-capitalize white--text"
          @click="$emit('onDialogDetailLogOpen')"
          :disabled="isDisabled"
        >
          <v-icon :left="!$vuetify.breakpoint.xs"> mdi-more </v-icon>
          <span v-if="!$vuetify.breakpoint.xs">Detail Log</span>
        </v-btn>
      </div>

      <div v-if="mode == 'detail'">
        <slot name="opsiContent"> </slot>
      </div>
      <template v-slot:activator>
        <v-btn
          @click="$emit('onDialogOpen')"
          color="blue darken-2 white--text"
          fab
        >
          <v-icon v-if="fab"> mdi-close </v-icon>
          <v-icon v-else> mdi-view-grid </v-icon>
        </v-btn>
      </template>

      <div v-if="mode == ''">
        <!-- <v-btn fab @click="$emit('onDialogSearch')" small dark color="#FBC02D">
          <v-icon>mdi-magnify</v-icon>
        </v-btn> -->
        <v-btn fab @click="$emit('onDialogAddOpen')" small dark color="#0154DA">
          <v-icon>mdi-plus</v-icon>
        </v-btn>

        <v-btn
          @click="$emit('onDialogExportOpen')"
          class="white--text"
          fab
          small
          color="#097c7c"
        >
          <v-icon> mdi-file-export-outline</v-icon>
        </v-btn>
        <v-btn
          @click="$emit('onDialogFilterOpen')"
          class="white--text"
          fab
          small
          color="#09227C"
        >
          <v-icon> mdi-filter-variant</v-icon>
        </v-btn>
      </div>
    </v-speed-dial>
  </v-card>
</template>

<script>
export default {
  props: {
    isDetailLogNone: {
      type: Boolean,
      required: false,
      default: false,
    },

    isFixed: {
      type: Boolean,
      required: false,
      default: false,
    },
    isAbsolute: {
      type: Boolean,
      required: false,
      default: false,
    },
    isClass: {
      type: String,
      required: false,
      default: "",
    },
    /**
     * args slide-y, slide-y-reverse, slide-x, slide-x-reverse, scale
     */
    transitionTo: {
      type: String,
      required: false,
      default: "",
    },
    isHover: {
      type: Boolean,
      required: false,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    mode: {
      type: String,
      required: false,
      default: "",
    },
  },

  emits: [
    "onDialogOpen",
    "onDialogAddOpen",
    "onDialogUpdateOpen",
    "onDialogDeleteOpen",
    "onDialogExportOpen",
    "onDialogFilterOpen",
    "onDialogSearch",
  ],

  data: () => ({
    // data btn floating mobile
    isVisible: false,
    direction: "top",
    fab: false,
    fling: false,
    hover: false,
    tabs: null,
    transition: "slide-y-reverse-transition",
    // end data btn floating mobile
  }),
};
</script>

<style lang="scss" scoped>
/* This is for documentation purposes and will not be needed in your application */
#create .v-speed-dial {
  position: absolute;
}

#create .v-btn--floating {
  position: relative;
}
</style>
